
import { defineComponent } from "vue";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import SearchTable from "@/components/wizard/SearchTable.vue";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiEndpoint.js";
export default defineComponent({
  mixins: [apiEndpoint],
  name: "seip-submitted-claim-2-reassessment",
  components: {
    Datatable,
    SearchTable,
  },
  data() {
    return {
       bill: {
        tranche_id: "",
        entity_id: "",
        bill_no: "",
      },
      tranches: [],
      tranche1entityInfos: [],
      adTranche1entityInfos: [],
      tranche2entityInfos: [],
      adTranche2entityInfos: [],
      tranche3entityInfos: [],
      allBillNo: [],
      billList:[],
      allBill: [
        {
          trainee_count:"",
          bill_amount:"",
          contribution_percentage:"",
          contribution_amount:"",
          net_payable:"",
        } 
      ],
      api_url: "",
      loading: false,
      load: false,
      tolatTrainee: 0,
      tolatBillAmount: 0,
      contributionPay: 0,
      totalContributionAmount: 0,
      totalPayableAmount: 0,
      showBillList: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getTranche3Bill();
   // await this.getAdTranche2Bill();
    await this.getTranche2Bill();
    //await this.getAdTranche1Bill();
    await this.getTranche1Bill();
    this.api_url = this.VUE_APP_API_URL;
  },
  methods: {
    async getTranche1Bill() {
      this.load = true;
      await ApiService.get("bill/claim_2/reassessment/seip_submitted_list?tranche_id=1&bill_status=2")
        .then((response) => {
          this.tranche1entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAdTranche1Bill() {
       this.load = true;
      await ApiService.get("bill/claim_2/reassessment/seip_submitted_list?tranche_id=3&bill_status=2")
        .then((response) => {
          this.adTranche1entityInfos = response.data.data;
           this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche2Bill() {
       this.load = true;
      await ApiService.get("bill/claim_2/reassessment/seip_submitted_list?tranche_id=2&bill_status=2")
        .then((response) => {
          this.tranche2entityInfos = response.data.data;
           this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAdTranche2Bill() {
       this.load = true;
      await ApiService.get("bill/claim_2/reassessment/seip_submitted_list?tranche_id=4&bill_status=2")
        .then((response) => {
          this.adTranche2entityInfos = response.data.data;
           this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche3Bill() {
       this.load = true;
      await ApiService.get("bill/claim_2/reassessment/seip_submitted_list?tranche_id=5&bill_status=2")
        .then((response) => {
          this.tranche3entityInfos = response.data.data;
           this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    
   
  },
  setup() {
 
  },
});
